import Testimonials from '../../components/testimonials/testimonials.vue';
import Why from '../../components/why/why.vue';

export default {
  name: 'Safety',
  title: 'Safety: Travel safe with the localites community | be a localite',
  components: {
    Testimonials,
    Why
  }
}